// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'
import { Post } from '../../types'

// const SearchQuery = gql`
//   query Search($categorySlug: String) {
//     posts(
//       where: { search: $categorySlug, orderby: { field: DATE, order: DESC } }
//       first: 32
//     ) {
//       nodes {
//         id
//         title
//         uri
//         primaryCategory
//         primaryCategoryURI
//         featuredImage {
//           node {
//             mediaItemUrl
//             altText
//             spotlightDesktop: sourceUrl(size: HOMEPAGE_SPOTLIGHT_DESKTOP)
//             spotlightMobile: sourceUrl(size: HOMEPAGE_SPOTLIGHT_MOBILE)
//             homepagePreviewImage: sourceUrl(size: HOMEPAGE_PREVIEW)
//           }
//         }
//       }
//     }
//   }
// `

const SearchQuery = gql`
  query SearchResults($search: String) {
    searchResults(search: $search) {
      posts {
        title
        excerpt
        id
        spotlightDesktopImage
        spotlightMobileImage
        linkType
        primaryCategory
        primaryCategoryURI
        uri
      }
    }
  }
`

export interface ISearchQueryData {
  searchResults: {
    posts: Post[]
  }
}

export interface ISearchQueryVariables {
  search: string
}

export default SearchQuery

import * as React from 'react'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Intro from '../components/organisms/intro/Intro'
import Blocks from '../components/Blocks/Blocks'
import SkeletonBlocks from '../components/SkeletonBlocks/Skeleton'
import * as SkeletonStyles from '../components/SkeletonBlocks/SkeletonBlocks.module.scss'
import { Helmet } from 'react-helmet'
import { getUrlParams } from '../lib/utils'
// eslint-disable-next-line node/no-extraneous-import
import { useQuery } from '@apollo/client'
import type {
  ISearchQueryData,
  ISearchQueryVariables
} from '../graphql/queries/SearchQuery'
import SearchQuery from '../graphql/queries/SearchQuery'

interface SearchProps {
  location: Location
}

const Search = ({ location }: SearchProps) => {
  const { q }: any = getUrlParams(location.search)

  const { data, loading } = useQuery<ISearchQueryData, ISearchQueryVariables>(
    SearchQuery,
    {
      variables: { search: q }
    }
  )

  return (
    <>
      <Helmet>
        <title>Search | Muddy Stilettos</title>
      </Helmet>
      <Header />
      <Intro>
        <h1>Search results</h1>
        {q ? (
          <p>You searched for `{q}`, here are the results...</p>
        ) : (
          <p>Nothing found</p>
        )}
      </Intro>
      {loading ? (
        <SkeletonBlocks blocksNumber={12} order={0} layoutSpacing />
      ) : data && data.searchResults?.posts?.length > 0 ? (
        <Blocks
          title={`Search results`}
          posts={data.searchResults.posts}
          maxResults={32}
        />
      ) : (
        <div className={SkeletonStyles.NoBlocks}>
          Sorry, there was no result found for your search.
        </div>
      )}
      <Footer />
    </>
  )
}

export default Search
